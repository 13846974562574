const qs = require('qs')
const Request = require('@/repositories/request')
const { encodeQueryParams } = require('~/util/query')
class Invitation extends Request {
  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$invitations.find(stringifyParams)
  }

  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$invitations.count(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @return {Promise<unknown>}
   */
  resend(id) {
    return this.$strapi.$http.$get(`/invitations/${id}/resend`)
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi.$invitations.findOne(id)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi.$invitations.update(id, data)
  }

  countPilotInvitationsFromDate(dateStart) {
    return this.$strapi.$http.$get(
      `/invitations/pilot/count?date_start=${dateStart}`
    )
  }

  confirmInterview(body) {
    return this.$strapi.$http.$post('/invitations/interview', body)
  }

  previewMail(params) {
    return this.$strapi.$http.get(
      `/invitations/preview?${encodeQueryParams(params)}`
    )
  }

  previewReminderMail(params) {
    return this.$strapi.$http.get(
      `/invitations/reminder/preview?${encodeQueryParams(params)}`
    )
  }

  findAllJobSeekerFair(params) {
    return this.$strapi.$http.$get(
      `/invitations/job-seeker-fair?${encodeQueryParams(params)}`
    )
  }

  massResendInvitations(body) {
    return this.$strapi.$http.$post('/invitations/send-massive', body)
  }

  massResendAllInvitations(body) {
    return this.$strapi.$http.$post('/invitations/send-massive-all', body)
  }

  sendMassReminder(body) {
    return this.$strapi.$http.$post('/invitations/send-mass-reminder', body)
  }

  massValidateUsers(body) {
    return this.$strapi.$http.$post('/invitation/validate-excel', body)
  }

  newMassCreateUsers(body) {
    return this.$strapi.$http.$post('/users/massive', body)
  }

  newSingleCreateUsers(body) {
    body.user.user_origin = process.env.USER_ORIGIN || 'relink'
    return this.$strapi.$http.$post('/users/workers', body)
  }

  sendAllProjectInvitation(body) {
    return this.$strapi.$http.$post('/invitations/send-project', body)
  }

  sendInvitationByUserIds(body) {
    return this.$strapi.$http.$post('/invitations/send-massive-by-user', body)
  }
}

module.exports = Invitation
