export default {
  computed: {
    hasData() {
      return (
        this.graphicData?.[0]?.value ||
        this.graphicData?.[1]?.value ||
        this.graphicData?.[2]?.value
      )
    },
    colors() {
      if (!this.hasData) {
        return ['#DBDBDB']
      }
      return ['#C1F6F1', '#2CD5C4', '#1FB7A8']
    },
    pieOption() {
      return {
        color: this.colors,
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0%',
          left: 'center',
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '70%'],
            label: {
              show: false,
            },
            data: this.graphicData,
          },
        ],
      }
    },
  },
}
