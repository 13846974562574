//
//
//
//
//
//
//
//
//
//

import pieGraphicMixin from '@/mixins/pieGraphicMixin'
import GraphicCard from '~/components/DeliveryResults/GraphicCard.vue'

export default {
  name: 'ExperiencesSection',
  components: {
    GraphicCard,
  },
  mixins: [pieGraphicMixin],
  props: {
    experiencesData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    graphicData() {
      const { totalEducational, totalWork, totalOther } = this.experiencesData
      if (totalEducational || totalWork || totalOther) {
        return [
          { value: totalWork, name: 'Laborales' },
          { value: totalEducational, name: 'Formativa' },
          { value: totalOther, name: 'Otras' },
        ]
      }
      return [
        { value: 0, name: 'Laborales' },
        { value: 0, name: 'Formativa' },
        { value: 0, name: 'Otras' },
      ]
    },
  },
}
