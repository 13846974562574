//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import { ValidationObserver } from 'vee-validate'
import { FormInput } from '@/components/Form'
import { rutFilter } from 'vue-dni'
import ROLES from '@/constants/roles'
import { INVITATION } from '@/constants/mode_registers'
import CheckMultiLevel from '~/components/Shared/CheckMultiLevel'
import Button from '~/components/Shared/Button'
const { MESSAGE_TYPE } = require('~/constants/core')

export default {
  name: 'FormInvitationJobSeekerFair',
  components: {
    FormInput,
    ValidationObserver,
    CheckMultiLevel,
    Button,
    ckeditor: CKEditor.component,
  },
  props: {
    onSuccess: {
      default: () => {},
      type: Function,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const [{ roles }, locations] = await Promise.all([
      this.$strapi.$http.$get('/users-permissions/roles'),
      this.$repository.location.find({
        _limit: -1,
        _where: {
          type: 'region',
          code_contains: 'CHL',
        },
        populate: ['children.children'],
      }),
    ])
    const roleName = ROLES.ADMIN_UNEMPLOYED
    this.role = roles.find((role) => role.type === roleName)?.id
    this.locations = locations
    this.$store.commit('loading', false)
  },
  data: () => ({
    currentStep: 1,
    pilotName: null,
    pilotDescription: null,
    requirements: '',
    start: null,
    end: null,
    options: {
      languages: [],
      locations: [],
      situationLaboral: [
        {
          value: 'Empleado',
          label: 'Empleado',
        },
      ],
    },
    form: {
      situationLaboral: '',
      occupation: '',
      user: {
        names: null,
        lastnames: null,
        email: null,
        repeatEmail: null,
        identification_number: null,
        password: Math.random().toString(36).substring(7),
      },
    },
    locations: [],
    locationsSelected: [],
    scopePilot: null,
    editor: ClassicEditor,
    configEditor: {
      removePlugins: ['Heading'],
      toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote'],
    },
  }),
  computed: {
    minDateEnd() {
      return this.start || new Date()
    },
    formSubmit() {
      const { ...form } = this.form.user
      return {
        username: form.email,
        identification_number: form.identification_number,
        ...form,
      }
    },
  },
  watch: {
    form: {
      handler(val) {
        this.form.user.identification_number = rutFilter(
          this.form.user.identification_number
        )
      },
      deep: true,
    },
    locationsSelected() {
      this.scopePilot = this.locationsSelected.reduce((acc, val) => {
        acc += `- ${val.name}\n`
        return acc
      }, '')
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    nextStep() {
      this.currentStep = this.currentStep + 1
    },
    backStep() {
      this.currentStep = this.currentStep - 1
    },
    locationsIds() {
      return this.locationsSelected.map((location) => location.id)
    },
    async submit() {
      this.$store.commit('loading', true)
      try {
        const user = await this.$repository.user.create({
          ...this.formSubmit,
          role: this.role,
          mode_register: INVITATION,
          identification_number: this.form.user.identification_number.replaceAll(
            '.',
            ''
          ),
        })

        const slug = await this.$repository.contentManager.uuidGenerate({
          contentTypeUID: 'application::job-seeker-fair.job-seeker-fair',
          field: 'slug',
          data: { name: this.pilotName },
        })

        await this.$repository.jobSeekerFair.create({
          name: this.pilotName,
          description: this.pilotDescription,
          start: this.start,
          end: this.end,
          locations: this.locationsIds(),
          users: [user.id],
          requirements: this.requirements,
          slug: slug.data,
        })

        await this.$strapi.$invitations.create({ user })
        this.onSuccess()
        await this.$store.dispatch('core/showMessage', {
          message: `<p>Invitación enviada exitosamente a <b>${this.form.user.email}</b></p>`,
        })
      } catch (e) {
        await this.$store.dispatch('core/showMessage', {
          message: e.message,
          type: MESSAGE_TYPE.DANGER,
        })
      } finally {
        this.$store.commit('loading', false)
        this.$emit('refresh')
        this.closeDialog()
      }
    },
    changeMinLevelLocation(value) {
      if (value.checked) {
        this.locationsSelected.push(value)
      } else {
        this.locationsSelected = this.locationsSelected.filter(
          (location) => location.id !== value.id
        )
      }
    },
  },
}
