import ROLES from '@/constants/roles'
import { PERMISSION_LABELS } from '@/constants/permission-labels'

export default [
  {
    label: 'Inicio',
    to: '/inicio',
    children: [],
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: 'Mi empresa',
    to: null,
    roles: [ROLES.COMPANY, ROLES.RELINK],
    isDropdownActive: true,
    children: [
      {
        label: 'Reporte general',
        to: '/inicio',
        roles: [ROLES.COMPANY, ROLES.RELINK],
      },
      {
        label: 'Inventario de habilidades',
        to: '/mis-proyectos/inventario-habilidades',
        children: [],
        roles: [ROLES.COMPANY, ROLES.RELINK],
        permissions: [PERMISSION_LABELS.INVENTORY_SKILLS.BASE],
      },
    ],
  },
  {
    label: 'Ocupaciones',
    to: null,
    roles: [ROLES.RELINK],
    isDropdownActive: false,
    children: [
      {
        label: 'Ocupaciones personalizadas',
        to: '/ocupaciones-personalizadas',
        roles: [ROLES.RELINK],
      },
      {
        label: 'Biblioteca de ocupaciones',
        to: '/ocupaciones/search',
        roles: [ROLES.RELINK],
      },
    ],
  },
  {
    label: 'Mis proyectos',
    to: '/mis-proyectos',
    roles: [ROLES.COMPANY, ROLES.RELINK],
    children: [],
  },
  {
    label: 'Mis proyectos',
    to: null,
    roles: [ROLES.ADMIN_UNEMPLOYED],
    isDropdownActive: false,
    children: [
      {
        label: 'Ocupaciones requeridas',
        to: '/ocupaciones-requeridas/cesantes',
        children: [],
        roles: [ROLES.ADMIN_UNEMPLOYED],
      },

      {
        label: 'Biblioteca de ocupaciones',
        to: '/ocupaciones/search',
        roles: [ROLES.ADMIN_UNEMPLOYED],
      },
    ],
  },
  {
    label: '',
    roles: [ROLES.RELINK, ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: '',
    roles: [ROLES.RELINK, ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: 'Mis experiencias',
    to: '/experiencias',
    children: [],
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: 'Mis oportunidades',
    to: '/oportunidades/reconversion-laboral',
    roles: [ROLES.UNEMPLOYED],
    children: [],
  },
  {
    label: 'Mi inventario de habilidades',
    to: '/mi-inventario-habilidades',
    children: [],
    roles: [ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: 'Mi inventario de habilidades',
    to: '/mi-inventario-habilidades',
    children: [],
    roles: [ROLES.EMPLOYEE],
    permissions: [PERMISSION_LABELS.INVENTORY_SKILLS.BASE],
  },
  {
    label: 'Candidatos invitados',
    to: '/invitaciones/cesantes',
    children: [],
    roles: [ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: 'Mis trabajadores',
    to: '/mis-trabajadores',
    children: [],
    roles: [ROLES.COMPANY, ROLES.RELINK],
  },
  {
    label: 'Solicitudes de cuentas empresa',
    to: '/solicitudes/reuniones',
    children: [],
    roles: [ROLES.RELINK],
  },
  {
    label: 'Empresas',
    to: null,
    roles: [ROLES.RELINK],
    isDropdownActive: false,
    children: [
      {
        label: 'Invitaciones',
        to: '/empresas-piloto',
        children: [],
        roles: [ROLES.RELINK],
      },
      {
        label: 'Empresas demo',
        to: '/empresas-demo',
        children: [],
        roles: [ROLES.RELINK],
      },
      {
        label: 'Reportería',
        to: '/empresas-reporteria',
        children: [],
        roles: [ROLES.RELINK],
      },
    ],
  },
  {
    label: 'Piloto Cesante',
    to: null,
    roles: [ROLES.RELINK],
    isDropdownActive: false,
    children: [
      {
        label: 'Pilotos activos',
        to: '/piloto-cesantes',
        children: [],
        roles: [ROLES.RELINK],
      },
      {
        label: 'Crear piloto',
        to: '/piloto-cesantes/invitaciones',
        children: [],
        roles: [ROLES.RELINK],
      },
    ],
  },
  {
    label: '',
    roles: [ROLES.COMPANY, ROLES.RELINK, ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: '',
    roles: [ROLES.COMPANY, ROLES.RELINK, ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: 'Mis oportunidades',
    to: '/oportunidades',
    children: [],
    roles: [ROLES.EMPLOYEE],
    permissions: [PERMISSION_LABELS.MATCH_POTENTIAL.BASE],
  },

  {
    label: '',
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: '',
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: '',
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: '',
    roles: [ROLES.EMPLOYEE, ROLES.PUBLIC, ROLES.UNEMPLOYED],
  },
  {
    label: 'Mi perfil',
    to: '/perfil',
    children: [],
  },
  {
    label: 'Mi piloto',
    to: '/mi-piloto',
    roles: [ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: 'Configuración',
    to: '/configuracion',
    roles: [ROLES.COMPANY, ROLES.RELINK],
  },
  {
    label: 'Configuración',
    to: '/piloto-cesantes/configuracion',
    roles: [ROLES.ADMIN_UNEMPLOYED],
  },
  {
    label: 'Cerrar sesión',
    to: '/salir',
    children: [],
    useBrowser: true,
  },
]
