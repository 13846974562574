//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormInput } from '@/components/Form'
import { rutFilter } from 'vue-dni'
import ROLES from '@/constants/roles'
import COMPANY_TYPES from '@/constants/company-types'
import COMPANY from '@/constants/company'

export default {
  name: 'IndividualForm',
  components: { FormInput, ValidationObserver },
  filters: {
    cleanConstant(val) {
      return val.replaceAll('_', ' ')
    },
  },
  props: {
    onSuccess: {
      default: () => {},
      type: Function,
    },
    isPilot: {
      default: true,
      type: Boolean,
    },
    countries: {
      default: () => [],
      type: Array,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const [{ roles }, companyType, optionsPermissions] = await Promise.all([
      this.$strapi.$http.$get('/users-permissions/roles'),
      this.$repository.companyType.findByValuedOrCreate(COMPANY_TYPES.PILOT),
      this.$repository.permission.getAllNames(),
    ])
    const roleName = ROLES.COMPANY
    this.companyType = companyType
    this.optionsPermissions = optionsPermissions
    this.role = roles.find((role) => role.type === roleName).id
    this.selectedCompany =
      this.companies.length > 0 ? this.companies[0].id : null
    this.$store.commit('loading', false)
  },
  data: () => ({
    companies: [],
    companyName: null,
    permissionLevel: null,
    identification_number: null,
    companyType: null,
    ROLES,
    selectedCompany: null,
    radioRole: ROLES.UNEMPLOYED,
    optionsPermissions: [],
    country: null,
    options: {
      languages: [],
      locations: [],
      situationLaboral: [
        {
          value: 'Empleado',
          label: 'Empleado',
        },
      ],
    },
    form: {
      situationLaboral: '',
      occupation: '',
      user: {
        names: '',
        lastnames: '',
        email: '',
        repeatEmail: '',
        password: Math.random().toString(36).substring(7),
      },
    },
  }),
  computed: {
    formSubmit() {
      const { ...form } = this.form.user
      return {
        username: form.email,
        identification_number: form.identification_number,
        ...form,
      }
    },
  },
  watch: {
    form: {
      handler(val) {
        this.form.user.identification_number = rutFilter(
          this.form.user.identification_number
        )
      },
      deep: true,
    },
    identification_number(val) {
      this.identification_number = rutFilter(this.identification_number)
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async submit() {
      this.$store.commit('loading', true)
      try {
        const [request] = await this.$repository.meetingRequest.findAll({
          email: this.form.user.email,
          _sort: 'created_at:DESC',
          _limit: 1,
        })
        const company = await this.$strapi.$companies.create({
          name: this.companyName,
          company_type: this.companyType?.id,
          country: this.country?.id,
          identification_number: this.identification_number
            ? this.identification_number.replaceAll('.', '')
            : '',
          permission_level: this.permissionLevel,
          type: COMPANY.TYPE.CORPORATE,
          request: request?.id,
        })
        const user = await this.$repository.user.create({
          ...this.formSubmit,
          company,
          role: this.role,
        })
        await this.$strapi.$invitations.create({ user })
        this.onSuccess()
        this.$buefy.notification.open({
          message: `<p>Invitación enviada exitosamente a <b>${this.form.user.email}</b></p>`,
          type: 'is-success',
          queue: false,
          hasIcon: true,
          duration: 7000,
        })
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
          hasIcon: true,
          duration: 7000,
        })
      } finally {
        this.$store.commit('loading', false)
        this.$emit('refresh')
        this.closeDialog()
      }
    },
  },
}
