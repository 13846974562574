//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button'

export default {
  name: 'ModalChangePassword',
  components: {
    ModalWithTitleBar,
    Button,
    Multiselect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      companySelected: '',
      modalVisible: false,
      searchQuery: '',
    }
  },
  computed: {
    filteredCompanies() {
      if (this.searchQuery.length === 1) {
        return this.companies.filter((company) =>
          company.name?.toLowerCase().startsWith(this.searchQuery.toLowerCase())
        )
      } else {
        return this.companies.filter((company) =>
          company.name?.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      }
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    submitForm() {
      this.$emit('submit', this.companySelected)
      this.$emit('toggle')
    },
    updateSearchQuery(query) {
      this.searchQuery = query
    },
  },
}
