//
//
//
//
//
//
//
//
//
//

import pieGraphicMixin from '@/mixins/pieGraphicMixin'
import GraphicCard from '~/components/DeliveryResults/GraphicCard.vue'

export default {
  name: 'ProjectsSection',
  components: {
    GraphicCard,
  },
  mixins: [pieGraphicMixin],
  props: {
    projectsByStatus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    graphicData() {
      const { totalDraft, totalActive, totalCompleted } = this.projectsByStatus
      if (totalDraft || totalActive || totalCompleted) {
        return [
          { value: totalDraft, name: 'En creación' },
          { value: totalActive, name: 'En curso' },
          { value: totalCompleted, name: 'Finalizados' },
        ]
      }
      return [
        { value: 0, name: 'En creación' },
        { value: 0, name: 'En curso' },
        { value: 0, name: 'Finalizados' },
      ]
    },
  },
}
